import axios from "axios";
import router from "@/router";
import { Dialog } from "vant";

// 服务器本地ip和端口号
axios.defaults.baseURL = "http://8.148.20.88:8089/";
// axios.defaults.baseURL = "http://localhost:8089";
// 服务器公网ip和端口号
// axios.defaults.baseURL = "https://pmcsi.wisdri.com:8081";

const request = axios.create({
  timeout: 60000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

//设置全局请求拦截
request.interceptors.request.use((config) => {
  config.headers["Authorization"] = localStorage.getItem("token");
  config.headers["userId"] = localStorage.getItem("userId"); //存储全局用户id
  return config;
});

//设置全局响应拦截
request.interceptors.response.use(
  (response) => {
    let res = response.data;
    if (res.code === 200) {
      return response; //状态码正常，返回正常结果
    } else {
      if (res.code === 403) {
        console.log("登录过期，请重新登录");
        localStorage.removeItem("token");
        router.push("/");
      } else {
        console.log("系统异常00");
      }
      return Promise.reject(response.data.msg); //拒绝往前走
    }
  },
  (error) => {
    Dialog.alert({
      title: "提示",
      message: error.message,
    }).then(() => {
      // on close
    });
    console.log(error);
    if (error.response.data) {
      error.message = error.response.data.msg;
    }

    if (error.response.status === 401) {
      router.push("/");
    }

    if (error.message) {
      console.log(error.message);
    } else {
      console.log("系统异常");
    }
    return Promise.reject(error);
  }
);

export default request;
