import Vue from "vue";
import VueRouter from "vue-router";

// 引入一级组件
import DashBoard from "@/views/dashboard/DashboardView.vue";
// 懒加载
const Home = () => import("@/views/home/HomeView.vue");

const Mainview = () => import("@/views/Mainview.vue");
// 车辆通行
const CarAccess = () => import("@/views/CarAccess.vue");
// 车辆通行
const CarStop = () => import("@/views/CarStop.vue");
//无感通行
const SenselessPassage = () => import("@/views/SenselessPassage.vue");
const Park = () => import("@/views/park/ParkView.vue");
const Mine = () => import("@/views/user/UserView.vue");
const Login = () => import("@/views/login/LoginView.vue");
const Meeting = () => import("@/views/meeting/meeting.vue");
const MeetingRoom = () => import("@/views/meeting/meetingroom.vue");
const MeetingList = () => import("@/views/meeting/meetinglist.vue");
const MeetingOrder = () => import("@/views/meeting/meetingorder.vue");
const MeetingCheck = () => import("@/views/meeting/meetingcheck.vue");
const MyMeeting = () => import("@/views/meeting/mymeeting.vue");
const Allmeetingorderinfo = () => import("@/views/meeting/allmeetingorderinfo.vue");
const Reception = () => import("@/views/reception/reception.vue");
const ReceptionOrder = () => import("@/views/reception/receptionOrder.vue");
const ReceptionCheck = () => import("@/views/reception/receptionCheck.vue");
const ReceptionItem = () => import("@/views/reception/receptionItem.vue");
const Repair = () => import("@/views/repair/repair.vue");
const AllRepair = () => import("@/views/repair/allrepair.vue");
const MyRepair = () => import("@/views/repair/myrepair.vue");
const MyAddRepair = () => import("@/views/repair/addRepair.vue");
const Equipment = () => import("@/views/equipment/equipment.vue");
const MyRepairDispose = () => import("@/views/repair/myRepairDispose.vue");
const Suggestion = () => import("@/views/suggestion/addSuggest.vue");

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/login" }, // 原来是/login 企业微信授权登录开发时暂时屏蔽
  { path: "/mainview/:index", name: "Mainview", component: Mainview }, //会议室信息显示门户网站
  { path: "/login", component: Login },
  {
    path: "/dashboard",
    component: DashBoard,
    children: [
      { path: "/dashboard", redirect: "/dashboard/home" },
      { path: "home", name: "HomeView", component: Home },
      { path: "park", name: "park", component: Park },
      { path: "mine", component: Mine },
    ],
  },
  //车辆
  { path: "/carAccess", name: "CarAccess", component: CarAccess },
  //停车
  { path: "/CarStop", name: "CarStop", component: CarStop },
  //无感通行
  {
    path: "/SenselessPassage",
    name: "SenselessPassage",
    component: SenselessPassage,
  },
  //会议
  { path: "/meeting", name: "Meeting", component: Meeting },
  //会议室信息
  { path: "/meetingroom", name: "MeetingRoom", component: MeetingRoom },
  //会议室列表
  { path: "/meetinglist", name: "MeetingList", component: MeetingList },
  //我的会议室预约
  { path: "/mymeeting", name: "MyMeeting", component: MyMeeting },
  //s所有会议室预约情况列表
    { path: "/allmeetingorderinfo", name: "Allmeetingorderinfo", component: Allmeetingorderinfo },
  //会议室预约
  { path: "/meetingorder", name: "MeetingOrder", component: MeetingOrder },
  //会议室审核
  { path: "/meetingcheck", name: "MeetingCheck", component: MeetingCheck },
  //接待
  { path: "/reception", name: "Reception", component: Reception },
  //接待预约
  {
    path: "/receptionorder",
    name: "ReceptionOrder",
    component: ReceptionOrder,
  },
  //接待预约审核
  {
    path: "/receptioncheck",
    name: "ReceptionCheck",
    component: ReceptionCheck,
  },
  //接待预约审核
  { path: "/receptionitem", name: "ReceptionItem", component: ReceptionItem },
  //报修
  { path: "/repair", name: "Repair", component: Repair },
  //所有报修
  { path: "/allrepair", name: "AllRepair", component: AllRepair },
  //我的报修
  { path: "/myrepair", name: "MyRepair", component: MyRepair },
  //增加报修
  { path: "/addRepair", name: "AddRepair", component: MyAddRepair },
  //意见建议
  { path: "/Suggestion", name: "Suggestion", component: Suggestion },
  //维修工单处理
  {
    path: "/myrepairdispose",
    name: "MyRepairDispose",
    component: MyRepairDispose,
  },
  { path: "/equipment", name: "Equipment", component: Equipment }, //食堂
  {
    path: "/restaurant",
    name: "Restaurant",
    component: () => import("../views/restaurant/restaurant.vue"),
    meta: {
      title: "食堂-待开发",
    },
  },
];

const router = new VueRouter({
  routes,
});

//路由守卫
router.beforeEach((to, from, next) => {
  // console.log("to.path=", to.path);
  if (to.path === "/login") {
    next();
  } else {
    // const userId = localStorage.getItem("userId");
    const userId = "ZD44973"; //暂时用这个 企业微信授权登录开发时暂时屏蔽
    if (userId) {
      next();
    } else {
      next("/login");
    }
  }
});
export default router;
