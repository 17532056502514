import Vue from 'vue'
import Vuex from 'vuex'

//挂载Vuex
Vue.use(Vuex);

const store = new Vuex.Store({
    state:{
      username:"",//姓名
      authoritys:"",//权限
      userid:"",//企业微信userid
      department:"",//部门
      accesssToken:"",//机电产业园小程序accesss_token
      openLink:"",//小程序scheme
      weixin_accesssToken:"",//企业微信accesss_token
      userInfo: {},
    },
    mutations:{
   
    },
    actions:{
   
    },
    getters:{
   
    },
    modules:{
      
    }
  })
  export default store;