import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from "element-ui";
import vSelect from "vue-select";
import dataV from "@jiaminghi/data-view";
Vue.component("v-select", vSelect);
// 引入配置FastClick的js文件
import "@/plugins/FastClick";

// 引入全局样式
import "@/style/commen.less";
// 引入思源字体
import "@/assets/css/font.less";

// 引入vant
import Vant, { Lazyload } from "vant";
import "vant/lib/index.less";
import {
  Switch,
  Picker,
  Toast,
  Form,
  Field,
  Dialog,
  Notify,
  Tab,
  Tabs,
  DatetimePicker,
  Popup,
  DropdownMenu,
  DropdownItem,
  Checkbox,
  CheckboxGroup,
} from "vant";

//引入视频组件
import moment from "moment";

// 引入ant-design
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "ant-design-vue/dist/antd-with-locales";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

// 引入echart
import * as echarts from "echarts";

// 企业微信
import wx from "wecomjsdk";

// axios
import axios from "@/service/api/axios_v2.js";
// 引入tailwindcss
import "tailwindcss/tailwind.css";

// dayjs
var dayjs = require("dayjs");

// 引入lodash JavaScript工具类
var _ = require("lodash");

Vue.use(Vant);
Vue.use(dataV);
Vue.use(Element);
Vue.use(Picker);
Vue.use(Switch);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Notify);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Lazyload);
Vue.prototype.$echarts = echarts;
Vue.prototype.$wx = wx;
Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;
Vue.prototype.dayjs = dayjs;
Vue.prototype._ = _;
Vue.use(Antd);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
