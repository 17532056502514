<template>
  <a-config-provider :locale="locale">
    <div id="app" class="app-contaienr">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>

<style lang="less" scoped>
@import url("./assets/css/font.less");
.app-contaienr {
  height: 100%;
  width: 100%;
}
body {
  font-family: HanSans;
}
</style>
