<template>
  <div id="dashboard">
    <router-view></router-view>
    <van-tabbar fixed v-model="active">
      <van-tabbar-item to="home" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item to="park" icon="wap-home-o">园区</van-tabbar-item>
      <!-- <van-tabbar-item to="video" icon="play-circle-o">视频</van-tabbar-item> -->
      <van-tabbar-item to="mine" icon="contact">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      navHash: "",
    };
  },
  watch: {
    $route(to, from) {
      // console.log(to.path);
      if (to.path.includes("/dashboard/home")) {
        this.active = 0;
      } else if (to.path.includes("/dashboard/park")) {
        this.active = 1;
      } else if (to.path.includes("/dashboard/video")) {
        this.active = 2;
      } else if (to.path.includes("/dashboard/mine")) {
        this.active = 3;
      }
    },
  },
  created() {
    const navHash = window.location.hash;
    if (navHash.includes("#/dashboard/home")) {
      this.active = 0;
    } else if (navHash.includes("#/dashboard/park")) {
      this.active = 1;
    } else if (navHash.includes("#/dashboard/video")) {
      this.active = 2;
    } else if (navHash.includes("#/dashboard/mine")) {
      this.active = 3;
    }
  },

  methods: {},
};
</script>

<style lang="less" scoped>
#dashboard {
  height: 100%;
  width: 100%;
}
</style>
